// src/v1/pages/Page1.js 

import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LeadContext } from '../../contexts/LeadContext';
import InputMask from 'react-input-mask';

import {
  Container,
  QuestionText,
  InputField,
  ErrorMessage,
  ContinueButton,
  FormContainer,
} from '../styles/GlobalStyles';

const Page1 = () => {
  const { leadData, setLeadData } = useContext(LeadContext);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const updatedData = { ...leadData, ...data };
    setLeadData(updatedData);

    // Enviar apenas os dados preenchidos na Página 1
    await enviarDadosParaGoogleSheets(updatedData);

    // Redirecionamento correto para a próxima página dentro da mesma versão
    navigate('/v1/Page2');
  };

  const enviarDadosParaGoogleSheets = async (data) => {
    const urlEncodedData = new URLSearchParams(data).toString();
  
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbymiqBG41SeDHsFtwkOnFVZjmw-HYtMHyM_8-sJ-xiyadp5y-nqf8KRwwmKz2Hs672gPA/exec', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlEncodedData,
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Erro na resposta da API: ${response.status} - ${errorText}`);
      }
  
      const result = await response.json();
      console.log('Resposta do Google Apps Script:', result);
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
    }
  };

  const isPhoneNumberValid = (value) => {
    const digitsOnly = value.replace(/\D/g, '');
    return digitsOnly.length === 11;
  };

  return (
    <Container>
      
      <QuestionText>Preencha suas informações de contato</QuestionText>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            type="text"
            placeholder="Nome"
            {...control.register('nome', { required: true })}
            defaultValue={leadData.nome}
          />
          {errors.nome && <ErrorMessage>Este campo é obrigatório</ErrorMessage>}

          <InputField
            type="email"
            placeholder="E-mail"
            {...control.register('email', { required: true, pattern: /^\S+@\S+$/i })}
            defaultValue={leadData.email}
          />
          {errors.email && <ErrorMessage>Insira um e-mail válido</ErrorMessage>}

          <Controller
            name="telefone"
            control={control}
            defaultValue={leadData.telefone || ''}
            rules={{
              required: true,
              validate: isPhoneNumberValid,
            }}
            render={({ field }) => (
              <InputMask
                mask="(99) 99999-9999"
                {...field}
              >
                {(inputProps) => (
                  <InputField
                    {...inputProps}
                    type="text"
                    placeholder="Telefone"
                  />
                )}
              </InputMask>
            )}
          />
          {errors.telefone && (
            <ErrorMessage>Insira um telefone válido com DDD e número</ErrorMessage>
          )}

          <ContinueButton type="submit">CONTINUAR</ContinueButton>
        </form>
      </FormContainer>
    </Container>
  );
};

export default Page1;
