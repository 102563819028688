// src/Routes.js

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Importando páginas das diferentes versões

// Versão 1
import PageIntroV1 from './v1/pages/PageIntro';
import Page1V1 from './v1/pages/Page1';
import Page2V1 from './v1/pages/Page2';
import Page3V1 from './v1/pages/Page3';
import Page4V1 from './v1/pages/Page4';
import Page5V1 from './v1/pages/Page5';
import Page6V1 from './v1/pages/Page6';

// Versão 2
import PageIntroV2 from './v2/pages/PageIntro';
import Page1V2 from './v2/pages/Page1';
import Page2V2 from './v2/pages/Page2';
import Page3V2 from './v2/pages/Page3';
import Page4V2 from './v2/pages/Page4';
import Page5V2 from './v2/pages/Page5';
import Page6V2 from './v2/pages/Page6';

// Versão 3
import PageIntroV3 from './v3/pages/PageIntro';
import Page1V3 from './v3/pages/Page1';
import Page2V3 from './v3/pages/Page2';
import Page3V3 from './v3/pages/Page3';
import Page4V3 from './v3/pages/Page4';
import Page5V3 from './v3/pages/Page5';
import Page6V3 from './v3/pages/Page6';

// Versão 4
import PageIntroV4 from './v4/pages/PageIntro';
import Page1V4 from './v4/pages/Page1';
import Page2V4 from './v4/pages/Page2';
import Page3V4 from './v4/pages/Page3';
import Page4V4 from './v4/pages/Page4';
import Page5V4 from './v4/pages/Page5';
import Page6V4 from './v4/pages/Page6';

// Versão 5
import PageIntroV5 from './v5/pages/PageIntro';
import Page1V5 from './v5/pages/Page1';
import Page2V5 from './v5/pages/Page2';
import Page3V5 from './v5/pages/Page3';
import Page4V5 from './v5/pages/Page4';
import Page5V5 from './v5/pages/Page5';
import Page6V5 from './v5/pages/Page6';

// Versão 6
import PageIntroV6 from './v6/pages/PageIntro';
import Page1V6 from './v6/pages/Page1';
import Page2V6 from './v6/pages/Page2';
import Page3V6 from './v6/pages/Page3';
import Page4V6 from './v6/pages/Page4';
import Page5V6 from './v6/pages/Page5';
import Page6V6 from './v6/pages/Page6';

function AppRoutes() {
  return (
    <Routes>
      {/* Rotas para a versão 1 */}
      <Route path="/v1/PageIntro" element={<PageIntroV1 />} />
      <Route path="/v1/Page1" element={<Page1V1 />} />
      <Route path="/v1/Page2" element={<Page2V1 />} />
      <Route path="/v1/Page3" element={<Page3V1 />} />
      <Route path="/v1/Page4" element={<Page4V1 />} />
      <Route path="/v1/Page5" element={<Page5V1 />} />
      <Route path="/v1/Page6" element={<Page6V1 />} />

      {/* Rotas para a versão 2 */}
      <Route path="/v2/PageIntro" element={<PageIntroV2 />} />
      <Route path="/v2/Page1" element={<Page1V2 />} />
      <Route path="/v2/Page2" element={<Page2V2 />} />
      <Route path="/v2/Page3" element={<Page3V2 />} />
      <Route path="/v2/Page4" element={<Page4V2 />} />
      <Route path="/v2/Page5" element={<Page5V2 />} />
      <Route path="/v2/Page6" element={<Page6V2 />} />

      {/* Rotas para a versão 3 */}
      <Route path="/v3/PageIntro" element={<PageIntroV3 />} />
      <Route path="/v3/Page1" element={<Page1V3 />} />
      <Route path="/v3/Page2" element={<Page2V3 />} />
      <Route path="/v3/Page3" element={<Page3V3 />} />
      <Route path="/v3/Page4" element={<Page4V3 />} />
      <Route path="/v3/Page5" element={<Page5V3 />} />
      <Route path="/v3/Page6" element={<Page6V3 />} />

      {/* Rotas para a versão 4 */}
      <Route path="/v4/PageIntro" element={<PageIntroV4 />} />
      <Route path="/v4/Page1" element={<Page1V4 />} />
      <Route path="/v4/Page2" element={<Page2V4 />} />
      <Route path="/v4/Page3" element={<Page3V4 />} />
      <Route path="/v4/Page4" element={<Page4V4 />} />
      <Route path="/v4/Page5" element={<Page5V4 />} />
      <Route path="/v4/Page6" element={<Page6V4 />} />

      {/* Rotas para a versão 5 */}
      <Route path="/v5/PageIntro" element={<PageIntroV5 />} />
      <Route path="/v5/Page1" element={<Page1V5 />} />
      <Route path="/v5/Page2" element={<Page2V5 />} />
      <Route path="/v5/Page3" element={<Page3V5 />} />
      <Route path="/v5/Page4" element={<Page4V5 />} />
      <Route path="/v5/Page5" element={<Page5V5 />} />
      <Route path="/v5/Page6" element={<Page6V5 />} />

      {/* Rotas para a versão 6 */}
      <Route path="/v6/PageIntro" element={<PageIntroV6 />} />
      <Route path="/v6/Page1" element={<Page1V6 />} />
      <Route path="/v6/Page2" element={<Page2V6 />} />
      <Route path="/v6/Page3" element={<Page3V6 />} />
      <Route path="/v6/Page4" element={<Page4V6 />} />
      <Route path="/v6/Page5" element={<Page5V6 />} />
      <Route path="/v6/Page6" element={<Page6V6 />} />

      {/* Página padrão ou página não encontrada */}
      <Route path="*" element={<Navigate to="/v1/PageIntro" replace />} />
    </Routes>
  );
}

export default AppRoutes;
