// src/Pages/Page2.js

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadContext } from '../../contexts/LeadContext';

import {
  Container,
  QuestionText,
  OptionButton,
  ContinueButton,
} from '../styles/GlobalStyles';

const Page2 = () => {
  const { leadData, setLeadData } = useContext(LeadContext);
  const [selectedOption, setSelectedOption] = useState(leadData.objetivo || '');
  const navigate = useNavigate();

  const handleOptionClick = (objetivo) => {
    setSelectedOption(objetivo);
  };

  const enviarDadosParaGoogleSheets = async (data) => {
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbymiqBG41SeDHsFtwkOnFVZjmw-HYtMHyM_8-sJ-xiyadp5y-nqf8KRwwmKz2Hs672gPA/exec', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Erro na resposta da API: ${response.status} - ${errorText}`);
      }

      const result = await response.json();
      console.log('Resposta do Google Apps Script:', result);

    } catch (error) {
      console.error('Erro ao enviar dados:', error);
    }
  };

  const handleContinue = async () => {
    const uuid = localStorage.getItem('uuid');
    const updatedData = { ...leadData, objetivo: selectedOption, uuid };
    setLeadData(updatedData);

    if (selectedOption === 'Desejo construir minha própria usina') {
      // Enviar os dados para o Google Sheets
      await enviarDadosParaGoogleSheets(updatedData);

      // Redirecionar para o WhatsApp
      window.location.href = 'https://api.whatsapp.com/send?phone=554588144707&text=Ol%C3%A1!%20Tenho%20interesse%20em%20construir%20uma%20usina%20de%20energia%20solar.';
    } else {
      navigate('/v1/Page3');
    }
  };

  return (
    <Container>
      
      <QuestionText>O que você deseja?</QuestionText>
      <OptionButton
        onClick={() => handleOptionClick('Quero desconto em minha conta de energia (SEM instalar placas solares)')}
        selected={selectedOption === 'Quero desconto em minha conta de energia (SEM instalar placas solares)'}
      >
        Quero desconto em minha conta de energia <br/>(SEM instalar placas solares)
      </OptionButton>
  
      <OptionButton
        onClick={() => handleOptionClick('Desejo construir minha própria usina')}
        selected={selectedOption === 'Desejo construir minha própria usina'}
      >
        Desejo construir minha própria usina
      </OptionButton>
      <ContinueButton onClick={handleContinue} disabled={!selectedOption}>
        CONTINUAR
      </ContinueButton>
    </Container>
  );
};

export default Page2;
