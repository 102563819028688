// src/Pages/Page6.js
import React, { useContext, useEffect } from 'react';
import { LeadContext } from '../../contexts/LeadContext';

import Logo from '../components/Logo'; 
import {
  Container,
  QuestionText,
  VideoContainer,
  Iframe,
  ContinueButton,
} from '../styles/GlobalStyles';

const Page6 = () => {
  const { leadData } = useContext(LeadContext);

  useEffect(() => {
    enviarDadosParaGoogleSheets(leadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enviarDadosParaGoogleSheets = async (data) => {
    const urlEncodedData = new URLSearchParams(data).toString();
  
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbymiqBG41SeDHsFtwkOnFVZjmw-HYtMHyM_8-sJ-xiyadp5y-nqf8KRwwmKz2Hs672gPA/exec', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlEncodedData,
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Erro na resposta da API: ${response.status} - ${errorText}`);
      }
  
      const result = await response.json();
      console.log('Resposta do Google Apps Script:', result);
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
    }
  };
  
  

  return (
    <Container>
      <Logo />
      
      <QuestionText>
        Assista o vídeo abaixo para entender como funciona:
      </QuestionText>
      <VideoContainer>
        <Iframe
          src="https://www.youtube.com/embed/PhkMkw5-HFM?rel=0"
          title="COMO ECONOMIZAR ATÉ 15% EM SUA CONTA DE ENERGIA"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </Iframe>
      </VideoContainer>
      <ContinueButton
        onClick={() =>
          (window.location.href = 'https://api.whatsapp.com/send?phone=554588144707&text=Ol%C3%A1!%20Tenho%20interesse%20no%20desconto%20em%20minha%20conta%20de%20energia')
        }
      >
        FALAR COM UM CONSULTOR
      </ContinueButton>
    </Container>
  );
};

export default Page6;
