// src/App.js

import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { LeadProvider } from './contexts/LeadContext';
import AppRoutes from './Routes';
import ProgressBar from './components/ProgressBar';
// Importando estilos das diferentes versões
import GlobalStylesV1 from './v1/styles/GlobalStyles';
import GlobalStylesV2 from './v2/styles/GlobalStyles';
import GlobalStylesV3 from './v3/styles/GlobalStyles';
import GlobalStylesV4 from './v4/styles/GlobalStyles';
import GlobalStylesV5 from './v5/styles/GlobalStyles';
import GlobalStylesV6 from './v6/styles/GlobalStyles';

const AppContent = () => {
  const location = useLocation(); // Hook para obter a URL atual

  // Função para determinar os estilos globais com base na versão da URL
  const getGlobalStyles = () => {
    if (location.pathname.startsWith('/v1')) {
      return <GlobalStylesV1 />;
    } else if (location.pathname.startsWith('/v2')) {
      return <GlobalStylesV2 />;
    } else if (location.pathname.startsWith('/v3')) {
      return <GlobalStylesV3 />;
    } else if (location.pathname.startsWith('/v4')) {
      return <GlobalStylesV4 />;
    } else if (location.pathname.startsWith('/v5')) {
      return <GlobalStylesV5 />;
    } else if (location.pathname.startsWith('/v6')) {
      return <GlobalStylesV6 />;
    }
    return null; // Caso nenhuma versão seja correspondente, não aplica estilos
  };

  return (
    <LeadProvider>
      {getGlobalStyles()} {/* Aplica os estilos corretos */}
      <ProgressBar /> {/* Adicione o ProgressBar aqui */}
      <AppRoutes />
    </LeadProvider>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
