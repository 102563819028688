// src/Pages/Page4.js
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadContext } from '../../contexts/LeadContext';

import Logo from '../components/Logo'; 
import {
  Container,
  QuestionText,
  SliderContainer,
  SliderInput,
  PriceDisplay,
  ContinueButton,
} from '../styles/GlobalStyles';

const Page4 = () => {
  const { leadData, setLeadData } = useContext(LeadContext);
  const [gastoMensal, setGastoMensal] = useState(leadData.gastoMensal || 500);
  const navigate = useNavigate();

  const handleSliderChange = (e) => {
    setGastoMensal(e.target.value);
  };

  const handleContinue = () => {
    const uuid = localStorage.getItem('uuid');
    const updatedData = { ...leadData, gastoMensal, uuid };
    setLeadData(updatedData);

    navigate('/v5/Page5');
  };

  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(gastoMensal);

  return (
    <Container>
      <Logo />
      
      <QuestionText>Quanto você paga mensalmente por energia elétrica?</QuestionText>
      <PriceDisplay>{formattedValue}</PriceDisplay>
      <SliderContainer>
        <SliderInput
          type="range"
          min="0"
          max="10000"
          step="100"
          value={gastoMensal}
          onChange={handleSliderChange}
        />
      </SliderContainer>
      <ContinueButton onClick={handleContinue}>
        CONTINUAR
      </ContinueButton>
    </Container>
  );
};

export default Page4;
