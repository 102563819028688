// src/Pages/Page5.js
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadContext } from '../../contexts/LeadContext';

import Logo from '../components/Logo'; 
import {
  Container,
  QuestionText,
  ValueText,
  SubText,          // Importamos o novo componente estilizado
  DisclaimerText,   // Importamos o novo componente estilizado
  ContinueButton,
} from '../styles/GlobalStyles';

const Page5 = () => {
  const { leadData, setLeadData } = useContext(LeadContext);
  const navigate = useNavigate();

  useEffect(() => {
    const economiaAnualPrevista = leadData.gastoMensal * 12 * 0.2;
    const uuid = localStorage.getItem('uuid');
    const updatedData = { ...leadData, economiaAnualPrevista, uuid };
    setLeadData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = () => {
    navigate('/v4/Page6');
  };

  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(leadData.economiaAnualPrevista || 0);

  return (
    <Container>
      <Logo />
      
      <QuestionText>Por ano você poderia economizar</QuestionText>
      <ValueText>{formattedValue}</ValueText>
      <SubText>por ano</SubText>   {/* Adicionamos este texto */}
      <DisclaimerText>
        *Custo de disponibilidade + Média da taxa de iluminação pública{'\n'}
        Esses valores são aproximados e condicionados ao tipo de sistema, taxas de disponibilidade e iluminação pública
      </DisclaimerText>
      <ContinueButton onClick={handleContinue}>
        CONTINUAR
      </ContinueButton>
    </Container>
  );
};

export default Page5;
