// src/Pages/Page5.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadContext } from '../../contexts/LeadContext';

import {
  Container,
  QuestionText,
  ValueText,
  SubText,
  DisclaimerText,
  ContinueButton,
} from '../styles/GlobalStyles';

const Page5 = () => {
  const { leadData, setLeadData } = useContext(LeadContext);
  const [economiaAnual, setEconomiaAnual] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const P_atual = leadData.gastoMensal || 0;

    // Dados da tabela fornecida
    const tableData = [
      { consumoMensal: 100, faturaSemEnergia: 82.00, descontoPercebido: 9.42 },
      { consumoMensal: 200, faturaSemEnergia: 164.00, descontoPercebido: 11.44 },
      { consumoMensal: 246.01, faturaSemEnergia: 246.01, descontoPercebido: 12.17 },
      { consumoMensal: 328.01, faturaSemEnergia: 328.01, descontoPercebido: 12.45 },
      { consumoMensal: 410.01, faturaSemEnergia: 410.01, descontoPercebido: 12.12 },
      { consumoMensal: 820.01, faturaSemEnergia: 820.01, descontoPercebido: 11.63 },
      { consumoMensal: 1025.02, faturaSemEnergia: 1025.02, descontoPercebido: 12.30 },
      { consumoMensal: 1230.02, faturaSemEnergia: 1230.02, descontoPercebido: 12.20 },
      { consumoMensal: 1640.03, faturaSemEnergia: 1640.03, descontoPercebido: 12.60 },
      { consumoMensal: 2050.04, faturaSemEnergia: 2050.04, descontoPercebido: 12.39 },
      { consumoMensal: 2460.05, faturaSemEnergia: 2460.05, descontoPercebido: 12.17 },
      { consumoMensal: 3280.08, faturaSemEnergia: 3280.08, descontoPercebido: 12.13 },
      { consumoMensal: 3690.09, faturaSemEnergia: 3690.09, descontoPercebido: 12.23 },
      { consumoMensal: 4100.10, faturaSemEnergia: 4100.10, descontoPercebido: 12.25 },
      { consumoMensal: 4510.11, faturaSemEnergia: 4510.11, descontoPercebido: 12.30 },
      { consumoMensal: 5330.14, faturaSemEnergia: 5330.14, descontoPercebido: 12.49 },
      { consumoMensal: 6150.17, faturaSemEnergia: 6150.17, descontoPercebido: 12.56 },
      { consumoMensal: 6560.18, faturaSemEnergia: 6560.18, descontoPercebido: 12.31 },
      { consumoMensal: 7380.22, faturaSemEnergia: 7380.22, descontoPercebido: 13.11 },
      { consumoMensal: 8200.19, faturaSemEnergia: 8200.19, descontoPercebido: 13.33 },
    ];

    let descontoPercebido = 0;

    if (P_atual > 0) {
      // Encontrar os pontos mais próximos na tabela
      let lowerData = null;
      let upperData = null;

      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].faturaSemEnergia <= P_atual) {
          lowerData = tableData[i];
        }
        if (tableData[i].faturaSemEnergia >= P_atual) {
          upperData = tableData[i];
          break;
        }
      }

      if (!lowerData) {
        // P_atual é menor que o menor valor na tabela
        lowerData = tableData[0];
        upperData = tableData[0];
      } else if (!upperData) {
        // P_atual é maior que o maior valor na tabela
        lowerData = tableData[tableData.length - 1];
        upperData = tableData[tableData.length - 1];
      }

      if (lowerData.faturaSemEnergia === upperData.faturaSemEnergia) {
        // Correspondência exata
        descontoPercebido = lowerData.descontoPercebido;
      } else {
        // Interpolar o desconto percebido
        const fraction =
          (P_atual - lowerData.faturaSemEnergia) /
          (upperData.faturaSemEnergia - lowerData.faturaSemEnergia);
        descontoPercebido =
          lowerData.descontoPercebido +
          fraction * (upperData.descontoPercebido - lowerData.descontoPercebido);
      }
    }

    // Garantir que o desconto percebido não seja negativo
    descontoPercebido = Math.max(descontoPercebido, 0);

    // Objetivo 2: Aplicar o desconto percebido na quantia de pagamento anual
    const P_anual = P_atual * 12;
    const economiaAnual = (P_anual * descontoPercebido) / 100;

    setEconomiaAnual(economiaAnual);

    const uuid = localStorage.getItem('uuid');
    const updatedData = {
      ...leadData,
      descontoPercebido,
      economiaAnual,
      uuid,
    };
    setLeadData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = () => {
    navigate('/v1/Page6');
  };

  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(economiaAnual || 0);

  return (
    <Container>
      
      <QuestionText>Por ano você poderia economizar</QuestionText>
      <ValueText>{formattedValue}</ValueText>
      <SubText>por ano</SubText>
      <DisclaimerText>
        *Os valores são estimativas baseadas em sua fatura atual e podem variar.
        {'\n'}
        Estes valores são aproximados e sujeitos a alterações.
      </DisclaimerText>
      <ContinueButton onClick={handleContinue}>CONTINUAR</ContinueButton>
    </Container>
  );
};

export default Page5;
