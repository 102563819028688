// src/contexts/LeadContext.js

import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const LeadContext = createContext();

export const LeadProvider = ({ children }) => {
  const [leadData, setLeadData] = useState({});

  useEffect(() => {
    let storedUUID = localStorage.getItem('uuid');
    if (!storedUUID) {
      storedUUID = uuidv4();
      localStorage.setItem('uuid', storedUUID);
    }
    setLeadData((prevData) => ({ ...prevData, uuid: storedUUID }));
  }, []);

  return (
    <LeadContext.Provider value={{ leadData, setLeadData }}>
      {children}
    </LeadContext.Provider>
  );
};
