// src/v1/pages/PageIntro.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Container1,
  TitleText,
  SubtitleText,
  ContinueButton,
} from '../styles/GlobalStyles';

const PageIntro = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/v5/Page1');
  };

  return (
    <Container>
      
      <TitleText>Simulação do desconto em sua <br />Conta de Energia</TitleText>
      <Container1>
      <SubtitleText>
        Este processo é simples e levará aproximadamente
        <br />
        <strong style={{ fontSize: '48px', color: '#25D267' }}>20 segundos</strong>
      </SubtitleText>
      </Container1>
      <ContinueButton onClick={handleContinue}>CONTINUAR</ContinueButton>
    </Container>
  );
};

export default PageIntro;
