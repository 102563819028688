// v4/styles/GlobalStyles.js

import { createGlobalStyle } from 'styled-components';
import styled, { keyframes } from 'styled-components';

/* Estilos Globais */
const GlobalStyles = createGlobalStyle`
  /* Reset básico */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Fonte padrão e fundo escuro */
  body {
    font-family: 'Roboto', sans-serif;
    background-color: #121212; /* Fundo escuro */
    color: #ffffff; /* Texto claro */
    position: relative; /* Necessário para posicionamento do container */
  }

  /* Garantir que todos os elementos filhos ocupem a altura total */
  #root {
    height: 100%;
    min-height: 100vh;
  }
`;

export default GlobalStyles;

/* Componentes Estilizados */

/* Container principal */
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  position: relative;
  padding-bottom: 80px; /* Espaço para o botão fixo */
`;

// Novo Título
export const TitleText = styled.h1`
  font-size: 32px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
`;

// Novo Subtítulo
export const SubtitleText = styled.p`
  font-size: 24px;
  color: #cccccc;
  text-align: center;
  margin-bottom: 40px;
`;

export const Container1 = styled.div`
  position: fixed;
  top: 40%;

`;


export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os itens horizontalmente */
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza os campos dentro do formulário */
    width: 100%;
  }
`;

/* Texto da pergunta */
export const QuestionText = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
`;

/* Campo de entrada */
export const InputField = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 12px;
  margin-bottom: 5px;
  font-size: 16px;
  border: 1px solid #444;
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 4px;

  ::placeholder {
    color: #777;
  }
`;

/* Mensagem de erro */
export const ErrorMessage = styled.span`
  color: #ff4d4f;
  margin-bottom: 10px;
  font-size: 14px;
  
`;

/* Botão de continuar */
export const ContinueButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#555' : '#25D267')};
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold; /* Texto em negrito */
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 90%;
  max-width: 400px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? '#555' : '#25D267'};
  }
`;

/* Botão de opção */
export const OptionButton = styled.button`
  background-color: ${(props) => (props.selected ? '#25D267' : '#1e1e1e')};
  color: #ffffff;
  border: 1px solid #444;
  padding: 15px 20px;
  margin: 10px 0;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  text-align: left;

  &:hover {
    background-color: ${(props) => (props.selected ? '#25D267' : '#2c2c2c')};
  }
`;

/* Novo componente estilizado para "por ano" */
export const SubText = styled.p`
  font-size: 18px;
  color: #25D267;  /* Mesma cor de ValueText */
  margin-bottom: 40px;  /* Ajuste de espaçamento */
`;

/* Novo componente estilizado para o texto inferior */
export const DisclaimerText = styled.p`
  font-size: 12px;
  color: #a9a9a9;  /* Cinza claro */
  text-align: center;
  margin-top: 10px;  /* Ajustado para ficar mais próximo do botão */
  margin-bottom: 20px;  /* Ajuste conforme necessário */
  line-height: 1.4;
  white-space: pre-wrap;
`;

/* Barra de progresso */
export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #333;
  height: 10px;
  margin-bottom: 20px;
`;

const progressKeyframes = (fromWidth, toWidth) => keyframes`
  from { width: ${fromWidth}%; }
  to { width: ${toWidth}%; }
`;

export const Progress = styled.div`
  height: 100%;
  background-color: #25D267;
  width: ${(props) => props.width}%;
  animation: ${(props) =>
    progressKeyframes(props.$previousWidth, props.width)} 0.5s ease-in-out forwards;
`;

/* Slider personalizado */
export const SliderContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
`;

export const SliderInput = styled.input`
  width: 100%;
  appearance: none;
  height: 10px;
  background: #333;
  outline: none;
  border-radius: 5px;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: #25D267;
    cursor: pointer;
    border-radius: 50%;
  }
`;

/* Exibição do preço */
export const PriceDisplay = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffffff;
`;

/* Texto do valor */
export const ValueText = styled.h2`
  font-size: 48px;
  color: #25D267;
  margin-bottom: 3px;
`;

/* Mensagem de sucesso */
export const SuccessMessage = styled.p`
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
`;

/* Container do Vídeo */
export const VideoContainer = styled.div`
  width: 100%;
  max-width: 600px;
  aspect-ratio: 16/9;
  margin: 20px 0;
`;

/* Iframe do Vídeo */
export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
