// src/components/ProgressBar.js

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const ProgressBar = () => {
  const location = useLocation();

  const extractVersionAndPage = (path) => {
    const pathSegments = path.split('/').filter((segment) => segment);
    const version = pathSegments[0]; // Ex: 'v1'
    const page = pathSegments[1]; // Ex: 'Page1' ou 'PageIntro'
    return { version, page };
  };

  const { version, page } = extractVersionAndPage(location.pathname);

  const versions = ['v1', 'v2', 'v3', 'v4', 'v5', 'v6'];
  const isValidVersion = versions.includes(version);

  const pageOrder = [
    'PageIntro',
    'Page1',
    'Page2',
    'Page3',
    'Page4',
    'Page5',
    'Page6',
  ];
  const currentStep = pageOrder.indexOf(page);
  const totalSteps = pageOrder.length;

  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    if (!isValidVersion || currentStep === -1) {
      setProgressPercentage(0);
      return;
    }

    const newProgress = (currentStep / (totalSteps - 1)) * 100;
    setProgressPercentage(newProgress);
  }, [location.pathname, isValidVersion, currentStep, totalSteps]);

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#333',
        height: '15px',
        marginBottom: '20px',
      }}
    >
      <motion.div
        style={{
          height: '100%',
          backgroundColor: '#25D267',
        }}
        initial={false}
        animate={{ width: `${progressPercentage}%` }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      />
    </div>
  );
};

export default ProgressBar;
