// src/v4/components/Logo.js

import React from 'react';
import styled from 'styled-components';
import logoImage from './logo.png'; // Ajuste o caminho conforme necessário

const StyledLogo = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%; /* Tamanho proporcional da logo em relação à largura da viewport */
  height: auto; /* Mantém a proporção da imagem */
  opacity: 0.6; /* Opacidade de 80% */
  pointer-events: none; /* Permite que cliques passem pela logo */
  z-index: -1; /* Garante que a logo fique atrás dos outros elementos */

  /* Responsividade para telas menores (mobile) */
  @media (max-width: 600px) {
    width: 50%; /* Aumenta a largura da logo em dispositivos móveis */
  }
`;

const Logo = () => {
  return <StyledLogo src={logoImage} alt="Logo da Empresa" />;
};

export default Logo;
