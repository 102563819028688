// src/Pages/Page3.js
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadContext } from '../../contexts/LeadContext';

import Logo from '../components/Logo'; 
import {
  Container,
  QuestionText,
  OptionButton,
  ContinueButton,
} from '../styles/GlobalStyles';

const Page3 = () => {
  const { leadData, setLeadData } = useContext(LeadContext);
  const [selectedOption, setSelectedOption] = useState(leadData.perfil || '');
  const navigate = useNavigate();

  const handleOptionClick = (perfil) => {
    setSelectedOption(perfil);
  };

  const handleContinue = () => {
    const uuid = localStorage.getItem('uuid');
    const updatedData = { ...leadData, perfil: selectedOption, uuid };
    setLeadData(updatedData);

    navigate('/v6/Page4');
  };

  return (
    <Container>
      <Logo />
      
      <QuestionText>Onde será aplicado o desconto:</QuestionText>
      <OptionButton
        onClick={() => handleOptionClick('Casa')}
        selected={selectedOption === 'Casa'}
      >
        Casa
      </OptionButton>
      <OptionButton
        onClick={() => handleOptionClick('Empresa')}
        selected={selectedOption === 'Empresa'}
      >
        Empresa
      </OptionButton>
      <OptionButton
        onClick={() => handleOptionClick('Rural')}
        selected={selectedOption === 'Rural'}
      >
        Rural
      </OptionButton>
      <ContinueButton onClick={handleContinue} disabled={!selectedOption}>
        CONTINUAR
      </ContinueButton>
    </Container>
  );
};

export default Page3;
